import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
   // @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@500;700&display=swap');

    body{
       // font-family: 'Prompt', sans-serif;
       font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; 
}
        margin: 0px;
        padding: 0px;
     // color: #FFE8D1;
     // font-display: swap;
        font-size: 18px;
    }
`;

export default GlobalStyles;
