import { DefaultTheme } from "styled-components";

interface MyTheme extends DefaultTheme {
    color: {
        textDefault: string;
        black: string;
        gray: string;
        white: string;
        defaultBrand: string;
        facebookBlue: string;
    };
    fontSize: {
        small: string;
        normal: string;
        big: string;
        large: string;
        huge: string;
        gigantic: string;
    };
    fontWeight: {
        thin: string;
        normal: string;
        bold: string;
    };
}

const myTheme: MyTheme = {
    color: {
        textDefault: "#D2D2D2",
        black: "#0B0B0B",
        gray: "#1A1A1A",
        white: "#F4F4F4",
        defaultBrand: "#ab4d7a",
        facebookBlue: "#4267B2",
    },
    fontSize: {
        small: "0.89rem",
        normal: "1.15rem",
        big: "1.33rem",
        large: "1.66rem",
        huge: "2.2rem",
        gigantic: "2rem",
    },
    fontWeight: {
        thin: "400",
        normal: "500",
        bold: "600",
    },
};

export { myTheme };
